import { CharRange } from "./CharRange";
import { getRange, toNOMADCoding } from "./util";

export const locationCodes = toNOMADCoding(CharRange.Alphanumeric);

export const AFLDStatus = toNOMADCoding(
	["OPEN", "CLOSED", "CLOSED (DAMAGED)"],
	[..."ABE"],
);

export const AFLDWarn = toNOMADCoding(["GREEN", "YELLOW", "RED", "BLACK"]);

export const frequencyCodes = toNOMADCoding(CharRange.Alphanumeric);

export const RunwayNumbers = toNOMADCoding(getRange(1, 36).reverse());

export const RunwayWidths = toNOMADCoding([
	"> 200",
	...getRange(30, 200, 5).reverse(),
]);

export const MaterialTypes = toNOMADCoding([
	"GRAVEL",
	"SAND",
	"SILT",
	"CLAY",
	"GRASS",
	"SILTY GRAVEL",
	"CLAYEY GRAVEL",
	"SILTY SAND",
	"CLAYEY SAND",
	"CRUSHED CORAL (COMPACTED)",
	"CRUSHED CORAL",
	"SHELL",
	"ASPHALT SEAL (SEMI-PREPARED)",
	"CONCRETE SEAL (SEMI-PREPARED)",
	"STABILIZED SOIL",
	"SAND ASPHALT",
	"MACADAM",
	"ASPHALT",
	"CONCRETE",
	"ASPHALT/CONCRETE (MIXED SURFACE)",
	"RIGID PORTLAN CEMENT CONCRETE (PCC)",
	"GRAVEL, WELL-GRADED",
	"GRAVEL, POORLY GRADED",
	"SAND, WELL-GRADED",
	"SAND, POORLY GRADED",
	"CLAY CH",
	"CLAY CL",
	"SILT MH",
	"SILT ML",
	"AM-2 MAT",
]);

export const RunwayMarkings = toNOMADCoding([
	"AMP-4",
	"AMP-3 Day/Night (500' ZONE) Bi-directional",
	"AMP-3 Day (500' ZONE) Bi-directional",
	"AMP-3 Night (500' ZONE) Bi-directional",
	"AMP-3 Day/Night (1000' ZONE) Bi-directional",
	"AMP-3 Day (1000' ZONE) Bi-directional",
	"AMP-3 Night (1000' ZONE) Bi-directional",
	"AMP-3 Day/Night (500' ZONE) Uni-directional",
	"AMP-3 Day (500' ZONE) Uni-directional",
	"AMP-3 Night (500' ZONE) Uni-directional",
	"AMP-3 Day/Night (1000' ZONE) Uni-directional",
	"AMP-3 Day (1000' ZONE) Uni-directional",
	"AMP-3 Night (1000' ZONE) Uni-directional",
	"AMP-1 Day/Night (500' ZONE) Bi-directional",
	"AMP-1 Day (500' ZONE) Bi-directional",
	"AMP-1 Night (500' ZONE) Bi-directional",
	"AMP-1 Day/Night (1000' ZONE) Bi-directional",
	"AMP-1 Day (1000' ZONE) Bi-directional",
	"AMP-1 Night (1000' ZONE) Bi-directional",
	"Precision Instrument Markings",
	"Non-precision Instrument Markings",
	"Visual Markings",
	"Unmarked",
]);

export const DistanceAvailable = [
	toNOMADCoding(getRange(0, 15)),
	toNOMADCoding([...CharRange.Numeric].reverse()),
];

/* Environment */

let distAvailDict = [...CharRange.Alphanumeric];
distAvailDict.shift();
export const DistanceVisibility = toNOMADCoding(
	["Unknown", "> 8", ...getRange(0, 8, 0.25).reverse()],
	distAvailDict,
);

export const CeilingTypes = toNOMADCoding([
	"SKC",
	"FEW",
	"SCT",
	"BKN",
	"OVC",
	"VV",
	"Not Measured",
]);

export const CeilingAlts = [undefined, ...getRange(0, 9900, 100).reverse()];

/* Pavement */

export const PavementClasses = [
	{ label: "CBR", value: "C" },
	{ label: "PCN", value: "P" },
];

export const PCNs = [
	toNOMADCoding(getRange(0, 12)),
	toNOMADCoding(CharRange.Numeric),
];

export const PavementTypes = [undefined, ..."RF"];

export const SubgradeStrengths = [undefined, ..."ABCD"];

export const TirePressure = [undefined, ..."WXYZ"];

export const PCNDetermination = [undefined, ..."TU"];

export const RCR = toNOMADCoding(
	getRange(4, 23).reverse(),
	CharRange.Alphanumeric.slice(12, 32),
);

export const RFF = toNOMADCoding(getRange(0, 20, 5).reverse(), [..."PUZ50"]);

export const CBR = toNOMADCoding([...getRange(11, 79, 2).reverse(), "< 9"]);
export const Layer = toNOMADCoding(getRange(1, 36).reverse());

/* Weather */

export const WindDirection = [
	toNOMADCoding(getRange(1, 36)),
	toNOMADCoding(CharRange.Numeric)
]

export const WindVariability = toNOMADCoding(CharRange.Numeric);

export const WindVelocity = [...getRange(0, 40), "> 40" ]
export const GustVelocity = WindVelocity;

// These options exceed the limitations of the Base-36 numbering scheme.
// They will be split into 2, and converted to base 36 each.
// e.g.
// 62 = [6, 2]
export const Temperature = getRange(-40, 99).reverse();
export const DewPoint = getRange(-40, 99).reverse();

export const Altimeter = getRange(0, 99).reverse();

export const GNDCurrent = toNOMADCoding(["> 35", ...getRange(0, 34).reverse()]);

export const ParkMax = GNDCurrent;

export const WorkMax = GNDCurrent;

/* Medical */

export const ARFF = toNOMADCoding([
	"FAA-A/ICAO-1",
	"ICAO-2",
	"ICAO-3",
	"FAA-B/ICAO-4",
	"FAA-C/ICAO-5",
	"FAA-D/ICAO-6",
	"FAA-E/ICAO-7",
	"ICAO-8",
	"ICAO-9",
	"ICAO-10",
	"NO ARFF",
	"Unknown",
]);

export const MedLevels = toNOMADCoding([
	"None",
	"Combat Life Saver",
	"Unit Medics",
	"Aeromedical Evac Personnel",
	"Critical Care Air Transport Team",
	"Small Field Hospital",
	"Small Permanent Medical Building",
	"Large Field Hospital",
	"Large Permanent Medical Building",
	"Temporary Trauma Facility",
	"Permanent Trauma Facility",
]);

export const ITW = toNOMADCoding([
	"None",
	"< 1",
	...getRange(5, 30, 5),
	"45",
	"60",
]);

export const MHEType = toNOMADCoding([
	"None",
	"Forklift 4k",
	"Forklift 6k",
	"Forklift 10k",
	"Forklift 10k AT",
	"Forklift 13k AT",
	"Rollerized Tines",
	"K-Loader 25k",
	"Halvorsen NGSL Loader",
	"K-Loader 25k",
	"K-Loader 40k",
	"K-Loader 60k",
	"Wide Body Chochran",
	"Wide Body Wilson",
	"Wide Body Atlis",
	"Air Stairs Wide Body",
	"Highline Roller System",
]);

export const EquipmentQTY = toNOMADCoding([
	"> 34",
	...getRange(0, 34).reverse(),
]);

export const FuelTypes = toNOMADCoding([
	"JP-8 / F-34",
	"JP-4 / F-40",
	"JP-5 / F-44",
	"JP-8/SPK",
	"JP-8+100 / F-37",
	"Jet A",
	"Jet A-1 / F-35",
	"Jet B",
	"AN-8",
	"AVGAS / F-22",
]);

export const FuelQTY = toNOMADCoding([
	"> 350",
	...getRange(10, 350, 10).reverse(),
]);

export const SuitabilityCodes = toNOMADCoding(
	[
		"N/A",
		"ARMING AREA",
		"C-5",
		"C-130",
		"C-17",
		"KC-10",
		"KC-135",
		"KC-46",
		"COMBAT OFFLOAD AREA",
		"DROP ZONE",
		"RUNWAY ONLY OPS",
		"SPECIALIZED FUELING OPS",
		"LANDING ILLUSION",
		"MOUNTAINOUS TERRAIN",
		"ONE WAY IN/OUT",
		"DAY ONLY",
		"ARRESTING GEAR (CABLE)",
		"ARRESTING GEAR (WEB)",
		"VMC ONLY",
		"LANDING ZONE",
	],
	[..."0ABCDEFGHIJKLMORSTVZ"],
);



export * as IRIS from ".";
