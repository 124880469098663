import { OverlayToaster, Position, Intent } from "@blueprintjs/core";

import ClipboardJS from "clipboard";

import type { RootState } from "./store";
import { useSelector } from "react-redux";

import { CharRange } from "./CharRange";

export type NOMADBase = {
	label: string | number | undefined | null;
	value: string | number | undefined | null;
};

export function isMobileDevice() {
return () => (
		navigator.userAgent.includes("iPhone OS") ||
		navigator.userAgent.includes("Android")
	);
}

export const toaster = OverlayToaster.createAsync({
	className: "iris-toast",
	position: isMobileDevice() ? Position.BOTTOM : Position.TOP,
	maxToasts: 1,
	usePortal: true,
	canEscapeKeyClear: true,
});

export function usePreparedStateObject() {
	const state: RootState = useSelector((state: RootState) => state);

	return [
		state.location,
		state.afldstatus,
		state.afldwarn,
		state.rwyinuse,
		state.distavail,
		state.rwywidth,
		state.rwymaterial,
		state.cbrpcn,
		(() =>
			(state.cbrpcn as any).label === "P"
				? [
						state.pcn,
						state.pvmttype,
						state.subgrade,
						state.tirepsi,
						state.pcnmethod,
					]
				: [state.cbr, state.cbrlayer])(),
		state.rcr,
		state.rff,
		state.winddir,
		state.windvel,
		state.gustvel,
		state.visibledist,
		state.ceiltype,
		state.ceilalt,
		state.temp,
		state.dewpoint,
		state.alt,
		state.rwymark,
		state.arff,
		state.gndcurrent,
		state.parkmog,
		state.workmog,
		state.mhe,
		state.medlvl,
		state.itw,
		state.freq,
		state.windvar,
		state.suitability,
	];
}

/**
 *
 * @param o
 * @param property
 * @returns
 */
export const complexExtractLabel = (
	o: any,
	property: string = "label",
): string => {
	// base case: if input is not an array, return the appropriate label or default value
	if (!Array.isArray(o)) {
		return o && o[property] !== undefined && o[property] !== null
			? o[property]
			: "*";
	}

	// recurse case: flatten the array by mapping each element through complexExtractLabel and joining the results
	return o.map((o2: any) => complexExtractLabel(o2)).join("");
};

/**
 *
 * @param str
 * @param size
 * @returns
 */
export const chunked = (str: string | string[], size: number = 5): string => {
	let rounds =
		Math.floor(str.length / size) + Math.ceil((str.length % size) / size);
	let chunks: string[] = [];

	str = [...str].join("");

	for (let i = 0; i <= rounds; i++) {
		let start = i * size;
		let part = str.substring(start, start + size);
		chunks.push(part);
	}

	return chunks.join(" ");
};

/**
 *
 * @param chunked
 * @param delim
 * @returns
 */
export const unchunk = (
	chunked: string | string[],
	delim: string = " ",
): string => {
	const out = [...chunked].join("").split(delim);
	return out.join("");
};

export const importCode = (code: string) => {
	// Checks
	const len = code.length;

	if (len >= 58 && len <= 84) {
		const date = code.substring(0, 3);
		
		const nomad = code.substring(3);
		const pattern = railPattern(6, nomad.length);
		const decrypted = decryptRailFence(nomad, 6, pattern);
		const newLocation = nomad.split('').slice(0, 4);

		console.debug(date, nomad);
		return decrypted;
	
	}
};

export const overwriteState = async (state: any) => {
	navigator.vibrate(100); // haptic feedback
	await showToast("Code pasted from clipboard.", Intent.PRIMARY);
};

/**
 *
 * @returns
 */
export const convertToReportDate = (date: Date): string => {
	return [
		date.getMonth(),
		date.getDate() - 1,
		date.getHours(),
		Math.round(date.getMinutes() / 2), // rounded to nearest even
	]
		.map((d) => CharRange.Alphanumeric[d])
		.join("");
};

/**
 *
 * @param text
 * @param key
 * @param isEncrypt
 * @returns
 */
export function railfenceCipher(
	text: string,
	key: number,
	isEncrypt: boolean,
): string {
	const rows = new Array(key).fill("");
	let direction = 0;
	let result = "";

	// Encryption
	if (isEncrypt) {
		for (let i = 0; i < text.length; i++) {
			rows[direction] += text[i];
			direction = (direction + 1) % key;
		}
		result = rows.join("");
	}
	// Decryption
	else {
		const length = text.length;
		const cols = Math.ceil(length / key);
		const matrix = new Array(key).fill("").map(() => new Array(cols).fill(""));

		let row = 0;
		let col = 0;

		for (let i = 0; i < length; i++) {
			matrix[row][col] = text[i];
			if (row === 0) {
				direction = 1;
			} else if (row === key - 1) {
				direction = -1;
			}
			row += direction;
			col++;
		}

		for (let i = 0; i < key; i++) {
			result += matrix[i].join("");
		}
	}

	return result;
}

/**
 *
 * @param m
 * @param i
 * @param time
 */
export const showToast = async (
	m: string,
	i: Intent | undefined,
	time: number = 2000,
) => {
	(await toaster).show({
		message: m,
		intent: i,
		timeout: time,
	});
};

/**
 *
 * @param e
 */
export const copyToClipboard = async (e: any) => {
	const pattern = railPattern(6, unchunk(e.target.innerText).length);
	const text = unchunk(e.target.innerText);
	const date = text.substring(0, 3);
	const nomad = text.substring(4);

	const encrypted = encryptRailFence(nomad, 6, pattern);
	const encryptedWDate = date + encrypted;
	console.log(encrypted)
	console.log(encryptedWDate);
	console.log(decryptRailFence(encrypted, 6, pattern));

	const clipboard = new ClipboardJS(".iriscode-container", {
		text: () => encryptedWDate,
	});
	
	clipboard.on("success", async () => {
		await showToast("Text copied to clipboard.", Intent.SUCCESS);
		clipboard.destroy();
	});

	clipboard.on("error", async (e: any) => {
		await showToast("Failed to copy text to clipboard.", Intent.DANGER);
		console.error(e);
		clipboard.destroy();
	});
};

/**
 * Get a lit of numbers in an inclusive range in the provided increments.
 *
 * @param f
 * @param t
 * @param inc
 * @returns
 */
export function getRange(
	f: string | number,
	t: string | number,
	inc = 1,
): Array<undefined | string | number> {
	const toCharIf = (i: number) =>
		typeof f === "string" ? String.fromCharCode(i) : i;

	const fr = typeof f === "string" ? f.charCodeAt(0) : f;
	const to = typeof t === "string" ? t.charCodeAt(0) : t;

	return Array.from({ length: (to - fr) / inc + 1 }, (_, i) =>
		toCharIf(fr + i * inc),
	);
}

/**
 * Maps an array of strings or numbers to a list of objects, with assigned labels.
 * and values. Optional params include `dict` (custom dictionary for indexing) and `addUndefined`
 * (adds `undefined` at index 0)
 * e.g
 * > codify([1, 2, 3, 4, 5], [...ABCDE]);
 * [ {label: A, value: 1}, {label: B, value: 2}, ... ]
 *
 *
 */
export function toNOMADCoding(
	values: Array<any> | string,
	labels: Array<string> | string = CharRange.Alphanumeric,
	addUndefined = true,
): Array<NOMADBase> {
	const codified = [...values].map((value: any, index: number): NOMADBase => {
		return { label: labels[index].toString(), value: value };
	});
	if (addUndefined) codified.unshift({ label: undefined, value: undefined });
	return codified;
}

/**
 * Generates the pattern for encrypting/decrypting a message using the rail fence cipher.
 * @param rails - The number of rails in the rail fence cipher.
 * @param msgLength - The length of the message to be decrypted.
 * @returns - An array representing the pattern for encrypting/decrypting the message.
 */
function railPattern(
	rails: number,
	msgLength: number,
){
	const decryptPattern: number[] = [];
	let directionIndex = 0;
	let direction = true;

	for(let i = 0; i < msgLength; i++){
		decryptPattern.push(directionIndex);
		if(directionIndex === rails -1){
			direction = false;
		} else if (directionIndex === 0){
			direction = true;
		}

		if (direction){
			directionIndex++;
		} else {
			directionIndex--;
		}
	}
	return decryptPattern;
}

/**
 * Encrypts a given text using rail fence cipher algorithm.
 * 
 * @param text - The text to be encrypted.
 * @param numRails - The number of rails in the cipher.
 * @param encryptPattern - An array representing the pattern for encrypting the text.
 * @returns The encrypted message.
 */
export function encryptRailFence(
	text: string,
	numRails: number,
	encryptPattern: Array<number>,
){
	const message = text.replace(/\s/g, "");
	const rails : string[][] = Array(numRails).fill(null).map(() => [""]);
	for(let i = 0; i < message.length; i++){
		const rail  = encryptPattern[i];
		rails[rail].push(message[i]);
	}
	const encryptedMessage = rails.map(rail => rail.join("")).join("");
	return encryptedMessage;
}

/**
 * Decrypts a message using the rial fence cipher algorithm.
 *
 * @param message - The encrypted message to be decrypted.
 * @param numRails - The number of rails used in the encryption process.
 * @param decryptPattern - An array representing the pattern used to decrypt the message.
 * @returns the decrypted message.
 */
export function decryptRailFence(
	message: string,
	numRails: number,
	decryptPattern: Array<number>,
){
	const msgLength = message.length;
	const railLengths = Array(numRails).fill(0);

	//Fill the railLengths array with the number of characters in each rail
	for(let i = 0; i < msgLength; i++){
		railLengths[decryptPattern[i]]++;
	}

	const newMsg = [];
	let msgIndex = 0;
	const rails: Array<string[]> = Array(numRails).fill(null).map(() => []);
	//Split the message into rails
	for(let i = 0; i < numRails; i++){
		rails[i] = message.slice(msgIndex, msgIndex + railLengths[i]).split("");
		msgIndex += railLengths[i];
	}
	console.log("rails" ,rails);

	for(let i = 0; i < msgLength; i++){
		newMsg.push(rails[decryptPattern[i]].shift());
	}
	return newMsg.join("");
}