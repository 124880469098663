import React from 'react';

import getRoutes from '../routes';

import { Route, Link, useNavigate } from 'react-router-dom';

import { Card, Elevation } from '@blueprintjs/core';

import './landing.scss';

function Landing() {
  const navigate = useNavigate();

  return (
    <div className='apps-list c-row application-list'>
      {
        getRoutes().filter(route => route.type === 'application').map((route, index) => (
          <Card
            key={index}
            interactive={true}
            elevation={Elevation.TWO}
            className='app c-row__col c-row__col--1-1 -c-row__col--md-1-3'
            onClick={() => navigate(route.path)}
          >
                <img src={route.img} alt={route.path.substring(1).toUpperCase()} />
          </Card>
        ))
      }
    </div>
  )
}

export default Landing;
