import React, { useState, useEffect } from "react";

import { Swiper } from "swiper/react";

import {
	Section,
	SectionCard,
	FormGroup,
	ControlGroup,
} from "@blueprintjs/core";

import CodePreview from "./components/CodePreview";
import PasteButton from "./components/PasteButton";

import "../landing.scss";

import * as Forms from "./forms";

import { complexExtractLabel, usePreparedStateObject } from "./util";



function Nomad() {
	const [followingBottom, setFollowBottom] = useState(false);

	useEffect(() => {
		const handleScroll = (e: any) => {
			setFollowBottom(
				Math.ceil(window.innerHeight + window.scrollY) >=
					document.documentElement.scrollHeight,
			);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className="form-container">
			

			<div className="iris-form">
				<CodePreview />
				<PasteButton />
				<br />
				<Section
					className="iris-form-section"
					title="Location"
					icon="map-marker"
				>
					<SectionCard className="iris-card">
						<Forms.Location />
						<Forms.Frequency />
					</SectionCard>
				</Section>

				<Section className="iris-form-section" title="Runway" icon="airplane">
					<SectionCard className="iris-card">
						<Forms.Runway />
					</SectionCard>
				</Section>

				<Section className="iris-form-section" title="Environment" icon="tree">
					<SectionCard className="iris-card">
						<Forms.Environment />
					</SectionCard>
				</Section>

				<Section
					className="iris-form-section"
					title="Pavement"
					icon="color-fill"
				>
					<SectionCard className="iris-card">
						<Forms.Pavement />
					</SectionCard>
				</Section>

				<Section className="iris-form-section" title="Weather" icon="rain">
					<SectionCard className="iris-card">
						<Forms.Weather />
					</SectionCard>
				</Section>

				<Section className="iris-form-section" title="Medical" icon="pulse">
					<SectionCard className="iris-card">
						<Forms.Medical />
					</SectionCard>
				</Section>

				<Section title="Materiel Handling" icon="cube">
					<SectionCard>
						<Forms.Materiel />
					</SectionCard>
				</Section>

				<Section
					className="iris-form-section stickybottom"
					title="Suitability Codes"
					icon="tick"
				>
					<SectionCard>
						<Forms.Suitability />
					</SectionCard>
				</Section>
			</div>
		</div>
	);
}

export default Nomad;
