import React, { Key, useCallback } from "react";

import { importCode } from "../util";
import type { NOMADBase } from "../util";

import { actions, constructOption } from "../store/slices";

import { useDispatch } from "react-redux";
import * as IRIS from "../model";

import { Button } from "@blueprintjs/core";
import { toNumber } from "lodash";
import "./PasteButton.scss";
import {CharRange} from "./../CharRange";


type IRISKeys = 
    'locationCodes' | 'AFLDStatus' | 'frequencyCodes' | 'RunwayNumbers' | 'RunwayWidths' | 'MaterialTypes' | 'RunwayMarkings' | 
    'DistanceAvailable' | 'DistanceVisibility' | 'CeilingTypes' | 'CeilingAlts' | 'PavementClasses' | 'PCNs' | 'PavementTypes' | 
    'SubgradeStrengths' | 'TirePressure' | 'PCNDetermination' | 'RCR' | 'RFF' | 'CBR' | 'Layer' | 'WindDirection' | 'WindVelocity' | 
    'GustVelocity' | 'Temperature' | 'DewPoint' | 'Altimeter' | 'GNDCurrent' | 'ParkMax' | 'WorkMax' | 'ARFF' | 'MedLevels' |
    'ITW' | 'MHEType' | 'EquipmentQTY' | 'FuelTypes' | 'FuelQTY' | 'SuitabilityCodes' | 'WindVariability'

const remapDict = {
    "Z": -0, "Y": -1, "X": -2, "W": -3, "V": -4, "U": -5, "T": -6, "S": -7, "R": -8, "Q": -9,
    "0": 0, "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6, "7": 7, "8": 8, "9": 9,
};

/**
 * PasteButton component.
 * This component handles the logic for updating various slices of data based on the clipboard content.
 */
function PasteButton() {

    const dispatch = useDispatch();

    const [viewTextField, setViewTextField] = React.useState(false);
    const [codeInput, setCodeInput] = React.useState("");

    /**
     * Used to update the both temperature and dew data slice.
     * @param e - The key of the remap dictionary.
     * @param slice - The name of the data slice.
     * @param ones - The value for the ones field.
     */
    const updateTempDew = useCallback(
        (e: keyof typeof remapDict, slice: string, ones: any) => {
            let dict = Array.from(CharRange.Alphanumeric).reverse();

            dispatch(
                actions[slice].update({
                    index: 0,
                    value: { label: e, value: remapDict[e] }
                })
            )
            dispatch(
                actions[slice].update({
                    index: 1,
                    value: { label: toNumber(ones), value: toNumber(ones) }
                })
            )
        }, [dispatch]
    )

    /**
     * Update the altimeter data slice.
     * @param e - The value to update.
     * @param i - The index of the value.
     * @param slice - The name of the data slice.
     */
    const updateAltimeter = useCallback(
        (e: any, i: number, slice: string) => {

            const isENumber = toNumber(e);

            const isENan = isNaN(isENumber);
            dispatch(
                actions[slice].update({
                    index: i,
                    value: { label: !isNaN(isENumber) ? toNumber(e) : e, value: !isNaN(isENumber) ? toNumber(e) : e }
                })
            )
        }, [dispatch]
    )

    /**
     * Update a non-NOMAD coded slice.
     * @param e - The value to update.
     * @param slice - The name of the data slice.
     */
    const updateNonCodedSlice = useCallback(
        (e: any, slice: string) =>
            dispatch(
                actions[slice].update({
                    label: e,
                    value: e
                })
            ), [dispatch]);

    /**
     * Update a non-NOMAD coded array slice.
     * @param i - The index of the value.
     * @param e - The value to update.
     * @param slice - The name of the data slice.
     */
    const updateNonCodedArraySlice = useCallback(
        (i: number, e: any, slice: string) =>
            dispatch(
                actions[slice].update({
                    index: i,
                    value: { label: e, value: e }
                })
            ), [dispatch]);

    /**
     * Update any normal NOMAD coded array slice.
     * @param i - The index of the value.
     * @param e - The value to update.
     * @param slice - The name of the data slice.
     * @param key - The key to find the value.
     * @param valueArrayIndex - The index of the value array.
     */
    const updateArraySlice = useCallback(
        (i: number, e: any, slice: string, key: string, valueArrayIndex?: number) =>
            dispatch(
                actions[slice].update({
                    index: i,
                    value: { label: e, value: typeof valueArrayIndex === "undefined" ? findValueByLabel(e, key) : findValueByLabel(e, key, valueArrayIndex) },
                })), [dispatch]);

    

    const changeTextField = () => {
        setViewTextField(!viewTextField);
        return
    }

    /**
     * Update any normal NAMAD coded non-array slice.
     * @param e - The value to update.
     * @param slice - The name of the data slice.
     * @param key - The key to find the value.
     */
    const updateNonArraySlice = useCallback(
        (e: any, slice: string, key: string) =>
            dispatch(
                actions[slice].update({
                    label: e, value: findValueByLabel(e, key)
                })), [dispatch]);

    /**
     * Find the value by label of a NOMAD coded slice.
     * @param label - The label to search for.
     * @param slice - The name of the data slice.
     * @param arrayIndex - The index of the array.
     * @returns The value corresponding to the label.
     */
    function findValueByLabel(label: string, slice: string, arrayIndex?: number): string | number | undefined {

        const sliceData = (IRIS as Record<IRISKeys, any>)[slice as IRISKeys];
        if (!sliceData) {
            console.error(`Slice ${slice} not found`);

            return undefined;
        }

        const item = typeof arrayIndex === "undefined" ? sliceData.find((code: NOMADBase) => code.label === label) : sliceData[arrayIndex].find((code: NOMADBase) => code.label === label);
        console.log("item:   ", arrayIndex ? sliceData[arrayIndex] : sliceData)
        return item ? (item.value === null ? undefined : item.value) : undefined;
    }


    return (
        <div style={{width:'100%', textAlign:'center'}}>
        {!viewTextField && <Button
            className="centered-container-clear"
        onClick={async () => {
            const code: string | undefined = "*************P***********************************************";
            if(code){
                for (let i = 0; i < code.length; i++) {
                    if (i < 4) {
                        updateArraySlice(i, code[i], "location", "locationCodes");
                    }
                    else if (i === 6) {
                        updateNonArraySlice(code[i], "rwyinuse", "RunwayNumbers")
                    }
                    else if (i === 7) {
                        updateArraySlice(0, code[i], "distavail", "DistanceAvailable", 0)
                    }
                    else if (i > 7 && i < 11) {
                        updateArraySlice(i - 7, code[i], "distavail", "DistanceAvailable", 1)
                    }
                    else if (i === 11) {
                        updateNonArraySlice(code[i], "rwywidth", "RunwayWidths")
                    }
                    else if (i === 12) {
                        updateNonArraySlice(code[i], "rwymaterial", "MaterialTypes")
                    }
                    else if (i === 13) {
                        updateNonArraySlice(code[i], "cbrpcn", "PavementClasses")
                    }
                    else if (i > 13 && i < 20) {
                        if (code[13].toUpperCase() === "C") {
                            if (i < 17) {
                                updateArraySlice(i - 14, code[i], "cbr", "CBR")
                            }
                            else {
    
                                updateArraySlice(i - 17, code[i], "cbrlayer", "Layer")
    
                            }
                        }
                        else {
                            if (i === 14) {
                                updateArraySlice(i - 14, code[i], "pcn", "PCNs", 0)
                            }
                            else if (i === 15) {
                                updateArraySlice(i - 14, code[i], "pcn", "PCNs", 1)
                            }
                            else if (i === 16) {
                                updateNonCodedSlice(code[i], "pvmttype")
                            }
                            else if (i === 17) {
                                updateNonCodedSlice(code[i], "subgrade")
                            }
                            else if (i === 18) {
                                updateNonCodedSlice(code[i], "tirepsi")
                            }
                            else if (i === 19) {
                                updateNonCodedSlice(code[i], "pcnmethod")
                            }
    
                        }
                    }
                    else if (i === 20) {
                        updateNonArraySlice(code[i], "rcr", "RCR")
                    }
                    else if (i === 21) {
                        updateNonArraySlice(code[i], "rff", "RFF")
                    }

                    else if (i > 21 && i < 24) {
                        updateArraySlice(i - 22, code[i], "winddir", "WindDirection", i - 22)
                    }
                    else if (i === 24) {
                        updateNonArraySlice(code[i], "windvel", "WindVelocity")
                    }
                    else if (i === 25) {
                        updateNonArraySlice(code[i], "gustvel", "GustVelocity")
                    }
                    else if (i === 26) {
                        updateNonArraySlice(code[i], "visibledist", "DistanceVisibility")
                    }
                    else if (i === 27) {
                        updateNonArraySlice(code[i], "ceiltype", "CeilingTypes")
                    }
                    else if (i > 27 && i < 30) {
                        updateNonCodedArraySlice(i - 28, code[i], "ceilalt")
                    }
                    else if (i === 31 && code[i] !== "*") {
                        console.log("i: ", i)
                        updateTempDew(code[30] as keyof typeof remapDict, "temp", code[31])
                    }
                    else if (i === 32 && code[i] !== "*") {
                        updateTempDew(code[32] as keyof typeof remapDict, "dewpoint", code[33])
                    }
                    else if (i > 33 && i < 38) {
                        updateAltimeter(code[i], i - 34, "alt")
                        // updateNonCodedArraySlice(i - 33, code[i], "alt")
                    }
                    else if (i === 38) {
                        updateNonArraySlice(code[i], "rwymark", "RunwayMarkings")
                    }
                    else if (i === 39) {
                        updateNonArraySlice(code[i], "arff", "ARFF")
                    }
                    else if (i === 40){
                        updateNonArraySlice(code[i], "gndcurrent", "GNDCurrent")
                    }
                    else if (i === 41){
                        updateNonArraySlice(code[i], "parkmog", "GNDCurrent")
                    }
                    else if (i === 42){
                        updateNonArraySlice(code[i], "workmog", "GNDCurrent")
                    }
                    else if (i > 42 && i < 47){
                        i % 2 === 0 ? updateArraySlice(i - 43, code[i], "mhe", "MHEType") : updateArraySlice(i - 43, code[i], "mhe", "EquipmentQTY")
                    }
                    else if (i > 46 && i < 51){
                        i % 2 === 0 ? updateArraySlice(i - 43, code[i], "mhe", "FuelTypes") : updateArraySlice(i - 43, code[i], "mhe", "FuelQTY")
                    }
                    else if (i === 51){
                        updateNonArraySlice(code[i], "medlvl", "MedLevels")
                        console.log("medlvl: ", code[i])
                    }
                    else if (i === 52){
                        updateNonArraySlice(code[i], "itw", "ITW")
                    }
                    else if (i > 52 && i < 59) {
                        updateArraySlice(i - 53, code[i], "freq", "frequencyCodes")
                    }
                    else if(i === 59){
                        updateNonArraySlice(code[i], "windvar", "WindVariability")
                    }
                    else if (i > 59){
                        updateArraySlice(i - 60, code[i], "suitability", "SuitabilityCodes")
                    }
                }}
            
        }}
        >Clear Form</Button>}
        
        {!viewTextField && <Button
            className="centered-container-paste"
            onClick={changeTextField}
        >
            Paste Nomad Code
        </Button>}
        <div>{viewTextField && (
            <input 
        className="centered-container"
            type="text"
            onChange={(e) => setCodeInput(e.target.value)}
            placeholder="Paste Nomad Code Here"
            
        />
        
        )}
        
        {viewTextField && (
        <Button
        className="centered-container"
        onTouchStart={async () => {
            const clipboard = codeInput;
            if (clipboard) {
                const code = importCode(clipboard);
                if(code){
                    for (let i = 0; i < code.length; i++) {
                        if (i < 4) {
                            updateArraySlice(i, code[i], "location", "locationCodes");
                        }
                        else if (i === 6) {
                            updateNonArraySlice(code[i], "rwyinuse", "RunwayNumbers")
                        }
                        else if (i === 7) {
                            updateArraySlice(0, code[i], "distavail", "DistanceAvailable", 0)
                        }
                        else if (i > 7 && i < 11) {
                            updateArraySlice(i - 7, code[i], "distavail", "DistanceAvailable", 1)
                        }
                        else if (i === 11) {
                            updateNonArraySlice(code[i], "rwywidth", "RunwayWidths")
                        }
                        else if (i === 12) {
                            updateNonArraySlice(code[i], "rwymaterial", "MaterialTypes")
                        }
                        else if (i === 13) {
                            updateNonArraySlice(code[i], "cbrpcn", "PavementClasses")
                        }
                        else if (i > 13 && i < 20) {
                            if (code[13].toUpperCase() === "C") {
                                if (i < 17) {
                                    updateArraySlice(i - 14, code[i], "cbr", "CBR")
                                }
                                else {
        
                                    updateArraySlice(i - 17, code[i], "cbrlayer", "Layer")
        
                                }
                            }
                            else {
                                if (i === 14) {
                                    updateArraySlice(i - 14, code[i], "pcn", "PCNs", 0)
                                }
                                else if (i === 15) {
                                    updateArraySlice(i - 14, code[i], "pcn", "PCNs", 1)
                                }
                                else if (i === 16) {
                                    updateNonCodedSlice(code[i], "pvmttype")
                                }
                                else if (i === 17) {
                                    updateNonCodedSlice(code[i], "subgrade")
                                }
                                else if (i === 18) {
                                    updateNonCodedSlice(code[i], "tirepsi")
                                }
                                else if (i === 19) {
                                    updateNonCodedSlice(code[i], "pcnmethod")
                                }
        
                            }
                        }
                        else if (i === 20) {
                            updateNonArraySlice(code[i], "rcr", "RCR")
                        }
                        else if (i === 21) {
                            updateNonArraySlice(code[i], "rff", "RFF")
                        }

                        else if (i > 21 && i < 24) {
                            updateArraySlice(i - 22, code[i], "winddir", "WindDirection", i - 22)
                        }
                        else if (i === 24) {
                            updateNonArraySlice(code[i], "windvel", "WindVelocity")
                        }
                        else if (i === 25) {
                            updateNonArraySlice(code[i], "gustvel", "GustVelocity")
                        }
                        else if (i === 26) {
                            updateNonArraySlice(code[i], "visibledist", "DistanceVisibility")
                        }
                        else if (i === 27) {
                            updateNonArraySlice(code[i], "ceiltype", "CeilingTypes")
                        }
                        else if (i > 27 && i < 30) {
                            updateNonCodedArraySlice(i - 28, code[i], "ceilalt")
                        }
                        else if (i === 31 && code[i] !== "*") {
                            console.log("i: ", i)
                            updateTempDew(code[30] as keyof typeof remapDict, "temp", code[31])
                        }
                        else if (i === 32 && code[i] !== "*") {
                            updateTempDew(code[32] as keyof typeof remapDict, "dewpoint", code[33])
                        }
                        else if (i > 33 && i < 38) {
                            updateAltimeter(code[i], i - 34, "alt")
                            // updateNonCodedArraySlice(i - 33, code[i], "alt")
                        }
                        else if (i === 38) {
                            updateNonArraySlice(code[i], "rwymark", "RunwayMarkings")
                        }
                        else if (i === 39) {
                            updateNonArraySlice(code[i], "arff", "ARFF")
                        }
                        else if (i === 40){
                            updateNonArraySlice(code[i], "gndcurrent", "GNDCurrent")
                        }
                        else if (i === 41){
                            updateNonArraySlice(code[i], "parkmog", "GNDCurrent")
                        }
                        else if (i === 42){
                            updateNonArraySlice(code[i], "workmog", "GNDCurrent")
                        }
                        else if (i > 42 && i < 47){
                            i % 2 === 0 ? updateArraySlice(i - 43, code[i], "mhe", "MHEType") : updateArraySlice(i - 43, code[i], "mhe", "EquipmentQTY")
                        }
                        else if (i > 46 && i < 51){
                            i % 2 === 0 ? updateArraySlice(i - 43, code[i], "mhe", "FuelTypes") : updateArraySlice(i - 43, code[i], "mhe", "FuelQTY")
                        }
                        else if (i === 51){
                            updateNonArraySlice(code[i], "medlvl", "MedLevels")
                            console.log("medlvl: ", code[i])
                        }
                        else if (i === 52){
                            updateNonArraySlice(code[i], "itw", "ITW")
                        }
                        else if (i > 52 && i < 59) {
                            updateArraySlice(i - 53, code[i], "freq", "frequencyCodes")
                        }
                        else if(i === 59){
                            updateNonArraySlice(code[i], "windvar", "WindVariability")
                        }
                        else if (i > 59){
                            updateArraySlice(i - 60, code[i], "suitability", "SuitabilityCodes")
                        }
                    }}
                
            }
            changeTextField();
        }
        
    }
        onClick={changeTextField}
        >Enter</Button>)}  
        </div> 

        </div>

    );
}

export default PasteButton;
// "*************P********CE***********************************"