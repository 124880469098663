// store/index.ts
//
// Dynamically import reducers from slices in this folder.

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { isMobileDevice } from "../../util";

export type OptionType = {
	label?: string | number | null;
	index?: string | number | null;
	value: string | number | null;
};

/**
 * 
 * @param e 
 * @returns 
 */
const constructOption = (e: any): OptionType => {
	const attr = (e: any, a: string) =>
		e.currentTarget[e.currentTarget.selectedIndex].attributes[a];

	// Because mobile devices use the 'Legacy' Select component, the method of retrieving
	// the labels and values are different.
	let label = isMobileDevice() ? attr(e, "index")?.value : e.label;
	let value = isMobileDevice() ? attr(e, "value")?.value : e.value;

	return {
		label: label,
		value: value,
	};
};

const predefinedReducers = {
	replace: (state: any, action: PayloadAction<OptionType>) => {
		let { label, value } = action.payload;
		state.label = label;
		state.value = value;
	},

	array: (
		state: any,
		action: PayloadAction<{ index: number; value: OptionType }>,
	) => {
		let { payload } = action;
		state[payload.index] = payload.value;
	},

	// Payload comes in as if one value, is separated into parts and placed into an array.
	splitvalue: (state: any, action: PayloadAction<OptionType[]>) => {
		let { payload } = action;
		let value = [...payload];
		state = value;
	},
};

// Array of slices to generate at runtime.
const slices: Array<any> = [
  {
		name: "location",
		initialState: Array(4),
	},
	{
		name: "afldstatus",
	},
	{
		name: "afldwarn",
	},
	{
		name: "rwyinuse",
	},
	{
		name: "distavail",
		initialState: Array(4),
	},
	{
		name: "rwywidth",
	},
	{
		name: "rwymaterial",
	},
	{
		name: "cbrpcn",
		initialState: { label: "P", value: undefined },
	},
	{
		name: "cbr",
		initialState: Array(3),
	},
	{
		name: "cbrlayer",
		initialState: Array(3),
	},
	{
		name: "pcn",
		initialState: Array(2),
	},
	{
		name: "pvmttype",
	},
	{
		name: "subgrade",
	},
	{
		name: "tirepsi",
	},
	{
		name: "pcnmethod",
	},
	{
		name: "rcr",
	},
	{
		name: "rff",
	},
	{
		name: "winddir",
		initialState: Array(2),
	},
	{
		name: "windvel",
	},
	{
		name: "gustvel",
	},
	{
		name: "visibledist",
	},
	{
		name: "ceiltype",
	},
	{
		name: "ceilalt",
		initialState: Array(2),
	},
	{
		name: "temp",
		initialState: Array(2),
	},
	{
		name: "dewpoint",
		initialState: Array(2),
	},
	{
		name: "alt",
		initialState: Array(4),
	},
	{
		name: "rwymark",
	},
	{
		name: "arff",
	},
	{
		name: "gndcurrent",
	},
	{
		name: "parkmog",
	},
	{
		name: "workmog",
	},
	{
		name: "mhe",
		initialState: Array(8),
	},
	{
		name: "medlvl",
	},
	{
		name: "itw",
	},
	{
		name: "freq",
		initialState: Array(6),
	},
	{
		name: "windvar",
	},
	{
		name: "suitability",
		initialState: Array(0),
	},
	
];

const reducers: any = {};
const actions: any = {};

for (const def of slices) {
  if (!def.name) continue;

  const slice = createSlice({
    name: def.name,
    initialState:
      (Array.isArray(def.initialState)
        ? def.initialState.fill(undefined)
        : def.initialState) ?? ({} as OptionType),
    reducers: {
      update:
        def.defaultReducer ??
        (Array.isArray(def.initialState)
          ? predefinedReducers.array
          : predefinedReducers.replace),
      //reset: (state: any) => (state = def.initialState),
    },
  });

  actions[def.name] = slice.actions;
  reducers[def.name] = slice.reducer;
}

const createReducer = () =>
	combineReducers({
		...reducers,
	});

export { createReducer, actions, constructOption };
