import { useCallback } from 'react';
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

import {CharRange} from "../../CharRange";

// Model
import {
  WindDirection,
  WindVelocity,
  GustVelocity,
  Temperature,
  DewPoint,
  Altimeter,
  GNDCurrent,
  ParkMax,
  WorkMax,
  WindVariability,
} from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function WeatherForm() {
    const { winddir, windvel, gustvel, temp, dewpoint, alt, gndcurrent, parkmog, workmog, windvar }: any = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const updateWindDir = useCallback(
        (e: any, i: number) => dispatch(actions.winddir.update(
          {
            index: i,
          value: constructOption(e)
        }
        ))
        , [dispatch]);

    const updateWindVel = useCallback(
        (e: any) => dispatch(actions.windvel.update(constructOption(e)))
    , [dispatch]);

    const updateGustVel = useCallback(
        (e: any) => dispatch(actions.gustvel.update(constructOption(e)))
        , [dispatch]);

    // Special Case
    const updateTemp = useCallback(
        (e: any) => {
            let temp: any = constructOption(e).value;

            let dict = Array.from(CharRange.Alphanumeric).reverse();

            let tens = Math.sign(temp) * Math.floor(Math.abs(temp / 10));
            let ones = Math.abs(temp % 10);

            dispatch(actions.temp.update({
                index: 0, value: {
                  label: dict[Object.is(Math.sign(-0), Math.sign(tens))? 10 : tens < 0 ? 10 - tens : tens],
                  value: tens
                }
            }));
            dispatch(actions.temp.update({ index: 1, value: { label: ones, value: ones } }));
        }
        , [dispatch]);

    // Special Case
    const updateDewPoint = useCallback(
      (e: any) => {
          let dew: any = constructOption(e).value;

          let dict = Array.from(CharRange.Alphanumeric).reverse();

          let tens = Math.sign(dew) * Math.floor(Math.abs(dew / 10));
          let check = Object.is(-0,0)
          let ones = Math.abs(dew % 10);

          dispatch(actions.dewpoint.update({
              index: 0, value: {
                  label: dict[Object.is(Math.sign(-0), Math.sign(tens))? 10 : tens < 0 ? 10 - tens : tens],
                  value: tens
              }
          }));
          dispatch(actions.dewpoint.update({ index: 1, value: { label: ones, value: ones } }));
      }
      , [dispatch]);

    // Special Case 2
    const updateAltitude = useCallback(
        (i: number, e: any) => {
            let alt: any = constructOption(e).value;

            let dict = [...CharRange.Alphanumeric].reverse();

            let tens = Math.floor(alt / 10);
            let ones = alt % 10;

            dispatch(actions.alt.update({ index: (2 * i), value: { label: tens, value: tens } }));
            dispatch(actions.alt.update({ index: (2 * i + 1), value: { label: ones, value: ones } }));
        }
        , [dispatch]);

    const updateGndCur = useCallback(
        (e: any) => dispatch(actions.gndcurrent.update(constructOption(e)))
        , [dispatch]);

    const updateParkmog = useCallback(
        (e: any) => dispatch(actions.parkmog.update(constructOption(e)))
        , [dispatch]);

    const updateWorkmog = useCallback(
        (e: any) => dispatch(actions.workmog.update(constructOption(e)))
        , [dispatch]);

    const updateWindVar = useCallback(
      (e: any)=> dispatch(actions.windvar.update(constructOption(e))),
      [dispatch]);

  return (
    <>
      <ControlGroup fill>
        <FormGroup label="Wind Direction">
          <Select options={WindDirection[0]} value={winddir[0]?.value} onChange={(e:any)=> updateWindDir(e, 0)} />
          <Select options={WindDirection[1]} value={winddir[1]?.value} onChange={(e: any)=> updateWindDir(e, 1)}/>
        </FormGroup>

        <FormGroup label = "Wind Variability">
          <Select options={WindVariability} value={windvar?.value} onChange={updateWindVar} />
        </FormGroup>

        <FormGroup label="Wind (kts)">
          <Select options={WindVelocity} value={windvel?.value} onChange={updateWindVel} />
        </FormGroup>

        <FormGroup label="Gust Velocity">
          <Select options={GustVelocity} value={gustvel?.value} onChange={updateGustVel} />
        </FormGroup>
      </ControlGroup>

      <ControlGroup className="iris-cgroup" fill>
        <FormGroup label="Temperature">
          <Select options={Temperature} value={temp[0]?.value * 10 + ( Object.is(Math.sign(temp[0]?.value),-0) ? -1 * temp[1]?.value : Math.sign(temp[0]?.value) === -1 ?  temp[1]?.value * -1: temp[1]?.value)} onChange={updateTemp} />
        </FormGroup>

        <FormGroup label="Dew Point">
          <Select options={DewPoint} value={dewpoint[0]?.value * 10 + ( Object.is(Math.sign(dewpoint[0]?.value),-0) ? -1 * dewpoint[1]?.value : Math.sign(dewpoint[0]?.value) === -1 ?  dewpoint[1]?.value * -1: dewpoint[1]?.value)} onChange={updateDewPoint} />
        </FormGroup>

        <FormGroup label="Altimeter">
          <ControlGroup fill>
            {[...Array(2)].map((_, i) => (
                <Select key={i} options={Altimeter} value={alt[2 * i]?.value * 10  + alt[2 * i + 1]?.value} onChange={(e: any) => updateAltitude(i, e)} />
              ))}
          </ControlGroup>
        </FormGroup>
      </ControlGroup>

      <ControlGroup fill>
        <FormGroup label="Current on Ground">
          <Select options={GNDCurrent} value={gndcurrent?.value} onChange={updateGndCur} />
        </FormGroup>

        <FormGroup label="Parking Max on Ground">
          <Select options={ParkMax} value={parkmog?.value} onChange={updateParkmog} />
        </FormGroup>

        <FormGroup label="Working Max on Ground">
          <Select options={WorkMax} value={workmog?.value} onChange={updateWorkmog} />
        </FormGroup>
      </ControlGroup>
    </>
  );
}

export default WeatherForm;
