import { useCallback } from "react";
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import { locationCodes } from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function LocationForm() {
  const location: any = useSelector((state: RootState) => state.location);
  const dispatch = useDispatch();

  const updateLocation = useCallback(
    (i: number, e: any) =>
      dispatch(
        actions.location.update({
          index: i,
          value: constructOption(e),
        })) , [dispatch]);

  return (
    <FormGroup label="Location Code" fill>
      <ControlGroup className="iris-cgroup" fill>
        {[...Array(4)].map((_, i) => (
          <Select
            options={Object.values(locationCodes)}
            value={location[i]?.value ?? undefined}
            onChange={(e: any) => updateLocation(i, e)}
            fill={false}
          />
        ))}
      </ControlGroup>
    </FormGroup>
  );
}

export default LocationForm;
