import { useCallback } from 'react';
import { SegmentedControl, FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import {
  PavementClasses,
  PCNs,
  PavementTypes,
  SubgradeStrengths,
  TirePressure,
  PCNDetermination,
  RCR,
  RFF,
  CBR,
  Layer,
} from "../../model";

// State imports
import type { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { actions, constructOption } from "../../store/slices";

function PavementForm() {
    const { cbrpcn, cbr, cbrlayer, pcn, pvmttype, subgrade, tirepsi, pcnmethod, rcr, rff }: any = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const updatePCNs = useCallback(
        (i: number, e: any) => dispatch(actions.pcn.update({ index: i, value: constructOption(e) }))
    , [dispatch]);

    const updatePaveType = useCallback(
        (e: any) => dispatch(actions.pvmttype.update(constructOption(e)))
    , [dispatch]);

    const updateSubgrade = useCallback(
    (e: any) => dispatch(actions.subgrade.update(constructOption(e))), [dispatch]);

    const updatePSI = useCallback(
        (e: any) => dispatch(actions.tirepsi.update(constructOption(e))), [dispatch]
    );

    const updatePCNMethod = useCallback(
        (e: any) => dispatch(actions.pcnmethod.update(constructOption(e))), [dispatch]
    );

    const updateCBR = useCallback(
        (i: number, e: any) => dispatch(actions.cbr.update({ index: i, value: constructOption(e) })), [dispatch]);

    const updateCBRLayer = useCallback(
        (i: number, e: any) => dispatch(actions.cbrlayer.update({ index: i, value: constructOption(e) })), [dispatch]
    );

    const updateRCR = useCallback(
    (e: any) => dispatch(actions.rcr.update(constructOption(e))), [dispatch]);

    const updateRFF = useCallback(
    (e: any) => dispatch(actions.rff.update(constructOption(e))), [dispatch]);

    const el_PCNSection = (
        <>
            <ControlGroup className="iris-cgroup" fill>
                <FormGroup label="Pavement Classification Number">
                    <ControlGroup fill>
                        {[...Array(2)].map((_, i) => (
                            <Select options={PCNs[i]} value={pcn[i]?.value} onChange={(e: any) =>  updatePCNs(i, e)} />
                        ))}
                    </ControlGroup>
                </FormGroup>
            </ControlGroup>

            <ControlGroup className="iris-cgroup" fill>
                <FormGroup label="Pavement Type">
                    <Select options={PavementTypes} value={pvmttype?.value} onChange={updatePaveType} />
                </FormGroup>
                
                <FormGroup label="Subgrade Strength">
                    <Select options={SubgradeStrengths} value={subgrade?.value} onChange={updateSubgrade} />
                </FormGroup>
            </ControlGroup>
            
            <ControlGroup className="iris-cgroup" fill>
                <FormGroup label="Tire Pressure">
                    <Select options={TirePressure} value={tirepsi?.value} onChange={updatePSI} />
                </FormGroup>
                
                <FormGroup label="Method of PCN Determination">
                    <Select options={PCNDetermination} value={pcnmethod?.value} onChange={updatePCNMethod} />
                </FormGroup>
            </ControlGroup>
        </>
    );
    
    const el_CBRSection = (
        <>
            <ControlGroup fill>
                <FormGroup label="California Bearing Ratio">
                    <ControlGroup className="iris-cgroup" fill vertical>
                        {[...Array(3)].map((_, i) => (
                            <Select options={CBR} value={cbr[i]?.value} onChange={(e: any) => updateCBR(i, e)} />
                        ))}
                    </ControlGroup>
                </FormGroup>
                
                <FormGroup label="Layer">
                    <ControlGroup className="iris-cgroup" fill vertical>
                        {[...Array(3)].map((_, i) => (
                            <Select options={Layer} value={cbrlayer[i]?.value} onChange={(e: any) => updateCBRLayer(i, e)} />
                        ))}
                    </ControlGroup>
                </FormGroup>
            </ControlGroup>
        </>
    );

  return (
    <>
      <SegmentedControl
        className="iris-segmentedcontrol"
        options={PavementClasses}
        value={
          cbrpcn?.label
        }
        onValueChange={(value: string) => dispatch(actions.cbrpcn.update({ label: value[0] }))}
        inline
        fill
        intent="primary"
      />
      <div id="iris-paveclass">
        {cbrpcn?.label == "P" ? el_PCNSection : el_CBRSection}

        <ControlGroup className="iris-cgroup" fill>
          <FormGroup label="Runway Condition Report">
            <Select options={RCR} value={rcr?.value} onChange={updateRCR} />
          </FormGroup>

          <FormGroup label="Rolling Distance">
            <Select options={RFF} value={rff?.value} onChange={updateRFF} />
          </FormGroup>
        </ControlGroup>
      </div>
    </>
  );
}

export default PavementForm;
