import { useCallback } from 'react';
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import { MHEType, EquipmentQTY, FuelTypes, FuelQTY } from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function MedicalForm() {
    const mhe: any = useSelector((state: RootState) => state.mhe);
    const dispatch = useDispatch();

    // TODO mhe is a special case - define custom reducer
    const updateMHE = useCallback((i: number, e: any) => {
        console.debug(constructOption(e))
        dispatch(actions.mhe.update({ index: i, value: constructOption(e) }));
    }, [dispatch]);

  return (
    <>
      <ControlGroup className="iris-cgroup" fill>
        <FormGroup label="Equipment 1">
            <Select options={MHEType} value={mhe[0]?.value} onChange={(e: any) => updateMHE(0, e)} />
        </FormGroup>

        <FormGroup label="Quantity">
            <Select options={EquipmentQTY} value={mhe[1]?.value} onChange={(e: any) => updateMHE(1, e)} />
        </FormGroup>
      </ControlGroup>

      <ControlGroup className="iris-cgroup" fill>
          <FormGroup label="Equipment 2">
              <Select options={MHEType} value={mhe[2]?.value} onChange={(e: any) => updateMHE(2, e)} />
          </FormGroup>

          <FormGroup label="Quantity">
              <Select options={EquipmentQTY} value={mhe[3]?.value} onChange={(e: any) => updateMHE(3, e)} />
          </FormGroup>
      </ControlGroup>

      <ControlGroup className="iris-cgroup" fill>
          <FormGroup label="Fuel Type 1">
              <Select options={FuelTypes} value={mhe[4]?.value} onChange={(e: any) => updateMHE(4, e)} />
          </FormGroup>

          <FormGroup label="Quantity">
              <Select options={FuelQTY} value={mhe[5]?.value} onChange={(e: any) => updateMHE(5, e)} />
        </FormGroup>
      </ControlGroup>

      <ControlGroup className="iris-cgroup" fill>
        <FormGroup label="Fuel Type 2">
          <Select options={FuelTypes} value={mhe[6]?.value} onChange={(e: any) => updateMHE(6, e)} />
        </FormGroup>

        <FormGroup label="Quantity">
          <Select options={FuelQTY} value={mhe[7]?.value} onChange={(e: any) => updateMHE(7, e)} />
        </FormGroup>
      </ControlGroup>
    </>
  );
}

export default MedicalForm;
