import { useCallback, useEffect, useState } from 'react';
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import { SuitabilityCodes } from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function SuitabilityForm() {
    const [followingBottom, setFollowBottom] = useState(false);

    const suitability: any = useSelector((state: RootState) => state.suitability);
    const dispatch = useDispatch();

    const updateSuitability = useCallback((i: number, e: any) => {
        dispatch(actions.suitability.update({ index: i, value: constructOption(e) }));
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = (e: any) => {
            setFollowBottom(Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => { if (followingBottom) { window.scrollTo(0, document.body.scrollHeight) } }, [suitability]);

    return (
        <FormGroup label="Suitability Codes">
            <ControlGroup className="iris-cgroup" fill vertical>
                {/* TODO: Render up to 20 elements dynamically: num used + 1 */}
                {[...Array(suitability.length + (suitability.length >= 19 ? (20 - suitability.length) : 2))].map((_, i) => (
                    <Select
                        value={suitability[i]?.value}
                        options={SuitabilityCodes}
                        disabled={i > suitability.length}
                        onChange={(e: any) => updateSuitability(i, e)}
                    />
                ))}
            </ControlGroup>
        </FormGroup>
    );
}

export default SuitabilityForm;
