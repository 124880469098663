import { useEffect, useState, useRef } from "react";
import { Intent } from "@blueprintjs/core";

import ClipboardJS from "clipboard";

import {
	chunked,
	unchunk,
	usePreparedStateObject,
	complexExtractLabel,
	copyToClipboard,
	showToast,
	importCode,
	convertToReportDate,
} from "../util";

function CodePreview() {
	const [lastChanged, setLastChanged] = useState(new Date());
	const [isTouching, setTouching] = useState(false);
	const [touchTimestamp, setTouchTime] = useState(-1);

	const state = usePreparedStateObject();

	const refIsTouching = useRef(false);
	refIsTouching.current = isTouching;

	const refTouchTime = useRef(-1);
	refTouchTime.current = touchTimestamp;

	const touchStart = async (e: any) => {
		setTouchTime(e.timeStamp);
		setTouching(true);

		setTimeout(() => {
			// Check if the user is still touching
			if (refIsTouching.current && refTouchTime.current === e.timeStamp) {
				// Read text from clipboard
				navigator.clipboard.readText().then(async (text: string) => {
					importCode(text);
				});
			}
		}, 500);
	};

	const touchEnd = async (e: any) => {
		setTouching(false);
		setTouchTime(-1);
	};

	// Show notification if ClipboardJS is unsupported on this browser/platform.
	if (!ClipboardJS.isSupported()) {
		showToast("Clipboard interaction is unsupported.", Intent.DANGER);
	}

	// useEffect(() => setLastChanged(new Date()), [state]);

	return (
		<div
			onClick={copyToClipboard}
			onTouchStart={touchStart}
			onTouchEnd={touchEnd}
			className="iriscode-container"
		>
			<span key={lastChanged.getTime() / 1000} className="iriscode noselect">
				{chunked([
					convertToReportDate(lastChanged),
					...complexExtractLabel(state),
				])}
			</span>
		</div>
	);
}

export default CodePreview;
