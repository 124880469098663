import Nomad from "./applications/nomad";

import NomadLogo from "./applications/nomad/nomad.svg";

const getRoutes = () => [
  {
    type: "application",
    path: "/nomad",
    element: <Nomad />,
    img: NomadLogo,
  },
  {
    type: "application",
    path: "/another_app",
    element: <></>,
  },
];

export default getRoutes;
