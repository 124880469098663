import { useCallback } from 'react';
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import { ARFF, MedLevels, ITW } from "../../model";

// State imports
import type { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { actions, constructOption } from "../../store/slices";

function MedicalForm() {
    const { arff, medlvl, itw }: any = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const updateARFF = useCallback((e: any) => dispatch(actions.arff.update(constructOption(e))), [dispatch]);

    const updateMedLevel = useCallback((e: any) => dispatch(actions.medlvl.update(constructOption(e))), [dispatch]);

    const updateITW = useCallback((e: any) => dispatch(actions.itw.update(constructOption(e))), [dispatch]);

  return (
    <>
      <ControlGroup className="iris-cgroup" fill>
        <FormGroup label="ARFF">
          <Select options={ARFF} value={arff?.value} onChange={updateARFF} />
        </FormGroup>

        <FormGroup label="Medical Level Available">
          <Select options={MedLevels} value={medlvl?.value} onChange={updateMedLevel} />
        </FormGroup>
      </ControlGroup>

      <FormGroup label="Integrated Threat Warning">
        <Select options={ITW} value={itw?.value} onChange={updateITW} />
      </FormGroup>
    </>
  );
}

export default MedicalForm;
