import { useCallback } from "react";
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import {
  RunwayNumbers,
  RunwayWidths,
  MaterialTypes,
  RunwayMarkings,
  DistanceAvailable,
} from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function RunwayForm() {
  const { rwyinuse, rwywidth, rwymaterial, rwymark, distavail }: any = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const updateRunway = useCallback(
    (e: any) =>
      dispatch(
        actions.rwyinuse.update(
            constructOption(e)
        ),
      ),
    [dispatch],
  );

  const updateWidth = useCallback(
    (e: any) =>
      dispatch(
        actions.rwywidth.update(
            constructOption(e)
        ),
      ),
    [dispatch],
  );

  const updateMaterial = useCallback(
    (e: any) => {
      dispatch(
        actions.rwymaterial.update(
            constructOption(e)
        ),
      );
    },
    [dispatch],
  );

  const updateMarkings = useCallback(
    (e: any) =>
      dispatch(
        actions.rwymark.update(
            constructOption(e)
        ),
      ),
    [dispatch],
  );

  const updateDistance = useCallback(
    (i: number, e: any) =>
      dispatch(
        actions.distavail.update({
          index: i,
          value: constructOption(e)
        }),
      ),
    [dispatch],
  );

  return (
    <>
      <ControlGroup className="iris-cgroup" fill>
        <FormGroup label="Runway in Use">
          <Select
            options={RunwayNumbers}
            value={rwyinuse?.value}
            onChange={updateRunway}
          />
        </FormGroup>

        <FormGroup label="Width">
          <Select
            options={RunwayWidths}
            value={rwywidth?.value}
            onChange={updateWidth}
          />
        </FormGroup>
      </ControlGroup>

      <ControlGroup fill>
        <FormGroup label="Material">
          <Select
            options={MaterialTypes}
            value={rwymaterial?.value}
            onChange={updateMaterial}
          />
        </FormGroup>

        <FormGroup label="Runway Markings">
          <Select
            options={RunwayMarkings}
            value={rwymark?.value}
            onChange={updateMarkings}
          />
        </FormGroup>
      </ControlGroup>

      <FormGroup label="Distance Available">
        <ControlGroup className="iris-cgroup" fill>
          <Select
            options={DistanceAvailable[0]}
            value={distavail[0]?.value}
            onChange={(e: any) => updateDistance(0, e)}
          />
          {[...Array(3)].map((_, i) => (
            <Select
              options={DistanceAvailable[1]}
              value={distavail[i + 1]?.value}
              onChange={(e: any) => updateDistance(i + 1, e)}
            />
          ))}
        </ControlGroup>
      </FormGroup>
    </>
  );
}

export default RunwayForm;
